import { connect } from 'react-redux';

import { ETariffBlockType, RootState } from 'pages/MainPage/types';
import { selectBlockSectionTitle } from 'pages/MainPage/store/selectors';

const mapStateToProps = (state: RootState, ownProps: { blockType: ETariffBlockType }) => ({
  sectionTitle: selectBlockSectionTitle(state, ownProps.blockType),
});

export const connector = connect(mapStateToProps);
