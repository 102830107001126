import React, { useCallback } from 'react';
import { clientOnlyHook } from '@beef/utils';

const _useCalculateTransformStyle = (
  sectionRef: React.RefObject<HTMLDivElement>,
  isBannerHidden: boolean,
): string => {
  return useCallback(() => {
    const sectionHeight = sectionRef.current?.getBoundingClientRect()?.height ?? 0;
    const bannerOffset = isBannerHidden ? 55 : 95;
    const sectionPos =
      window.scrollY > 0 ? 0 : Math.max(0, window.innerHeight - sectionHeight - bannerOffset);

    return `translate(-50%, -${sectionPos}px)`;
  }, [sectionRef, isBannerHidden])();
};

export const useCalculateTransformStyle = clientOnlyHook(_useCalculateTransformStyle);
