import { useTariffCardBlockType } from 'pages/MainPage/blocks/TariffCardBlock/components/Container/hooks/useTariffCardBlockType';

interface IBlockType {
  field_name: string | undefined;
  section_name: string | undefined;
}

/** Хук для подготовки чипсин */
export const usePrepareConnectButton = (text: string, tariffLabel: string | undefined) => {
  const blockType = useTariffCardBlockType() as IBlockType;

  const buttonData = {
    text,
    ...blockType,
    tariff_label: tariffLabel,
  };

  return JSON.stringify(buttonData);
};
