import { axiosBeelineRequest } from '@beef/utils';

import { getContent } from '../../../store';
import { EEndpoint, EResponseStatus, EUserType } from './constants';

export const fetchIsBeelineUser = async ({
  accountNumber,
  userType,
  baseUrl = '',
}: {
  accountNumber: string;
  baseUrl?: string;
  userType: EUserType;
}) => {
  try {
    const { shouldSkipValidation } = getContent();

    if (shouldSkipValidation) return true;

    const { CtnStatus } = await axiosBeelineRequest<{ CtnStatus?: EResponseStatus }>({
      url: `${baseUrl}${EEndpoint.CheckCtn}`,
      params: { ctn: accountNumber, userType },
    });

    return CtnStatus !== EResponseStatus.IsNonBeelineUser;
  } catch (e) {
    console.error(e);

    return false;
  }
};
