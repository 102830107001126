import React, { FC } from 'react';
import { AvatarGroup, Text } from '@carnica/ui-kit';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';

import { IOptions } from 'pages/MainPage/blocks/EthernetCardBlock/types';
import { RootState } from 'pages/MainPage/types';
import { selectForHomeOptions } from 'pages/MainPage/blocks/EthernetCardBlock/selectors';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент с опциями тарифа (набор иконок и заголовок) */
const _Options: FC<IOptions> = ({ title, icons }) => (
  <div className={cx('options')}>
    {title && (
      <Text color="secondary-invert" size="xs">
        {title}
      </Text>
    )}
    {icons && (
      <AvatarGroup className={cx('options__group')} items={icons} size="s" visibleCount={10} />
    )}
  </div>
);

export const Options = connect((state: RootState) => ({
  ...selectForHomeOptions(state),
}))(_Options);
