import React from 'react';
import { Container } from '@carnica/smart-kit';
import classNames from 'classnames/bind';
import { compose } from '@beef/redux';
import { withErrorBoundary } from '@beef/react';

import { Section } from 'pages/MainPage/layout/Section';
import { CardsBlock } from 'pages/MainPage/components/CardsBlock';
import { Devices } from 'pages/MainPage/components/Devices';
import { TariffCardBlock } from 'pages/MainPage/blocks/TariffCardBlock';
import { ETariffBlockType } from 'pages/MainPage/types';
import { TitleWithNavigation } from 'pages/MainPage/ui';
import { Anchor } from 'pages/MainPage/components/Anchor';
import { AdaptivityContainer } from 'pages/MainPage/utils/ResponsiveContainers';
import { BlockWrapper } from 'pages/MainPage/layout/BlockWrapper';
import { IDevice } from 'pages/MainPage/components/Devices/types';
import { CardsBlockProps } from 'pages/MainPage/components/CardsBlock/types';
import { TitleWithNavigationProps } from 'pages/MainPage/ui/TitleWithNavigation/types';
import { BannerCarouselBlock } from 'pages/MainPage/blocks/BannerCarouselBlock';
import { BannerCarouselProps } from 'pages/MainPage/blocks/BannerCarouselBlock/types';
import { Metric } from 'pages/MainPage/metric';
import { commonMetric } from 'pages/MainPage/metric/common';

import styles from './styles.pcss';
import { connector } from './connector';

const cx = classNames.bind(styles);

interface IForFamilyProps {
  anchor: string;
  cardsBlock: CardsBlockProps;
  devices: IDevice;
  inlineBanner: BannerCarouselProps;
  titleNavigation: TitleWithNavigationProps;
}

const _ForFamily: React.FC<IForFamilyProps> = ({
  titleNavigation,
  devices,
  cardsBlock,
  anchor,
  inlineBanner,
}) => {
  return (
    <>
      <Anchor anchorId={anchor} />
      <Metric
        asChild
        data={titleNavigation.title}
        events={{ commonMetric: ['handleBlockView'] }}
        mode="viewport"
        once
        threshold={0.2}
      >
        <Section
          heading={
            <TitleWithNavigation
              items={titleNavigation.items}
              onNavigationItemClick={(item) =>
                commonMetric.handleBlockNavClick(item.text, titleNavigation.title)
              }
              title={titleNavigation.title}
            />
          }
        >
          <BlockWrapper>
            <AdaptivityContainer minWidth={1025}>
              <Container.Flex direction="column" gap={60}>
                <Container.ColumnGrid className={cx('grid-block')} template={['1fr', '1fr']}>
                  <CardsBlock {...cardsBlock} blockTitle={titleNavigation.title} />
                  <TariffCardBlock blockType={ETariffBlockType.forFamily} />
                </Container.ColumnGrid>
              </Container.Flex>
            </AdaptivityContainer>
            <AdaptivityContainer maxWidth={1024} minWidth={481}>
              <Container.Flex direction="column" gap={20}>
                <TariffCardBlock blockType={ETariffBlockType.forFamily} />
                <CardsBlock {...cardsBlock} blockTitle={titleNavigation.title} />
              </Container.Flex>
            </AdaptivityContainer>
            <AdaptivityContainer maxWidth={480}>
              <Container.Flex direction="column" gap={4}>
                <TariffCardBlock blockType={ETariffBlockType.forFamily} />
                <CardsBlock {...cardsBlock} blockTitle={titleNavigation.title} />
              </Container.Flex>
            </AdaptivityContainer>
          </BlockWrapper>

          {inlineBanner?.slides && (
            <BlockWrapper>
              <BannerCarouselBlock {...inlineBanner} sectionTitle={titleNavigation.title} />
            </BlockWrapper>
          )}

          <BlockWrapper>
            <Devices {...devices} blockTitle={titleNavigation.title} />
          </BlockWrapper>
        </Section>
      </Metric>
    </>
  );
};

export const ForFamily = compose(withErrorBoundary({ fallback: null }), connector)(_ForFamily);
