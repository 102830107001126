import React from 'react';
import classNames from 'classnames/bind';
import { withErrorBoundary } from '@beef/react';

import style from './styles.pcss';
import { BrandCard, BrandType } from './components/BrandCard';

const cn = classNames.bind(style);

export type BrandsPropsType = {
  brands: BrandType[];
};

const _Brands: React.FC<BrandsPropsType> = ({ brands }): JSX.Element | null =>
  brands ?
    <ul className={cn('brands')}>
      {brands.map((brand: BrandType) => (
        <BrandCard {...brand} key={brand.href} />
      ))}
    </ul>
  : null;

export const Brands = withErrorBoundary({ fallback: null })(_Brands);
