import React from 'react';
import { Button } from '@carnica/ui-kit';
import classNames from 'classnames/bind';

import { IButton } from 'pages/MainPage/blocks/TariffCardBlock/types';
import { Metric } from 'pages/MainPage/metric';

import { usePrepareConnectButton } from './hooks/usePrepareConnectButton';
import { useConnectBasket } from './hooks/useConnectBasket';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Кнопка подключения */
export const ConnectButton: React.FC<IButton & { tariffLabel: string | undefined }> = ({
  basketPayload,
  text,
  tariffLabel,
}) => {
  const { isLoading, onConnect } = useConnectBasket({ basketPayload });
  const metricData = usePrepareConnectButton(text, tariffLabel);

  return (
    <div className={cx('button')}>
      <Metric data={metricData} events={{ tariffCardMetric: ['handleConnectClick'] }} mode="click">
        <Button loading={isLoading} onClick={onConnect} size="m">
          {text}
        </Button>
      </Metric>
    </div>
  );
};
