import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@carnica/ui-kit';
import { connect } from 'react-redux';
import { withRenderFlag } from '@beef/react';
import { compose } from '@beef/utils';

import { selectForHomeCardTitle } from 'pages/MainPage/blocks/EthernetCardBlock/selectors';
import { RootState } from 'pages/MainPage/types';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type TTitle = {
  title: string;
};

export const _Title: React.FC<TTitle> = ({ title }) => {
  return (
    <Text className={cx('component')} color="primary" size="m">
      {title}
    </Text>
  );
};

const mapStateToProps = (state: RootState) => ({
  title: selectForHomeCardTitle(state),
  shouldRender: !!selectForHomeCardTitle(state),
});

const connector = connect(mapStateToProps);

export const Title = compose<FC>(connector, withRenderFlag)(_Title);
