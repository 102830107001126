import React from 'react';
import { Text } from '@carnica/ui-kit';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';

import { EthernetCardBlockFee, IPriceText } from 'pages/MainPage/blocks/EthernetCardBlock/types';
import { RootState } from 'pages/MainPage/types';
import { selectForHomeCardPrice } from 'pages/MainPage/blocks/EthernetCardBlock/selectors';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Футер карточки */
const _Footer: React.FC<IPriceText & EthernetCardBlockFee> = ({ oldPrice, currentPrice }) => {
  return (
    <div className={cx('component')}>
      {oldPrice && (
        <Text className={cx('old-price')} color="tertiary" size="xs">
          {oldPrice}
        </Text>
      )}
      <Text color="primary-invert" size="xs">
        {currentPrice}
      </Text>
    </div>
  );
};

export const Footer = connect((state: RootState) => ({
  ...selectForHomeCardPrice(state),
}))(_Footer);
