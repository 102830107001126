import React from 'react';
import classNames from 'classnames/bind';
import { Button, Text } from '@carnica/ui-kit';
import { Container } from '@carnica/smart-kit';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';
import { MenuUrlTarget } from 'pages/MainPage/utils/utils';
import { Metric } from 'pages/MainPage/metric';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

export interface AppPromoteBlockProps {
  blockTitle: string;
  buttonText: string;
  href: string;
  isBlankUrlTarget: boolean;
  primaryText: string;
  qrCode: string;
  qrImage: string;
  qrScanText: string;
  secondaryText: string;
}

export const AppPromoteBlock: React.FC<AppPromoteBlockProps> = ({
  primaryText,
  secondaryText,
  href,
  isBlankUrlTarget,
  qrImage,
  qrScanText,
  qrCode,
  buttonText,
  blockTitle,
}) => {
  const isMobile = useIsMobileDimension();

  return (
    <div className={cx('wrapper')}>
      <Container.Flex
        direction="column"
        gap={8}
        justifyContent={isMobile ? 'center' : 'flex-start'}
      >
        <Text className={cx(isMobile && 'text-centered')} variant="body-medium">
          {primaryText}
        </Text>
        <Text className={cx(isMobile && 'text-centered')} color="secondary" variant="body-small">
          {secondaryText}
        </Text>
      </Container.Flex>

      {isMobile ?
        <Metric
          asChild
          data={JSON.stringify({ blockTitle, buttonText })}
          events={{ commonMetric: ['handleMnpButtonClick'] }}
          mode="click"
        >
          <Button
            className={cx('button')}
            href={href}
            tagName="a"
            target={MenuUrlTarget.get(isBlankUrlTarget)}
            view="secondary-invert"
          >
            {buttonText}
          </Button>
        </Metric>
      : <Container.Flex gap={12}>
          <img alt="" height={120} src={qrImage} width={120} />
          <Container.Flex direction="column" gap={4}>
            <Text color="secondary" variant="caption-medium">
              {qrScanText}
            </Text>
            <img alt="" src={qrCode} width={42} />
          </Container.Flex>
        </Container.Flex>
      }
    </div>
  );
};
