import { createSelector } from '@reduxjs/toolkit';
import { emptyObj } from '@beef/utils';
import { isEmpty } from 'lodash';

import { selectForHomeSection } from 'pages/MainPage/store/selectors';
import type {
  EthernetCardBlockFee,
  EthernetCardBlockProps,
  EthernetCardBlockSpeed,
  IChips,
  IOptions,
  IPresetText,
  IPriceText,
  ITariffCard,
  TFeeData,
  TSpeedInternet,
} from 'pages/MainPage/blocks/EthernetCardBlock/types';
import { ETHERNET_CARD_TYPES } from 'pages/MainPage/types';

// Общие селекторы

export const selectForHomeEthernetCardBlockContent = createSelector(
  [selectForHomeSection],
  (data) => data.ethernetCardBlock || (emptyObj as EthernetCardBlockProps),
);

export const selectForHomeData = createSelector(
  [selectForHomeSection],
  (data) => data.data?.home || emptyObj,
);

/** Селектор контента для карточки тарифа в блоке для Дома */
export const selectForHomeCardContent = createSelector(
  [selectForHomeEthernetCardBlockContent],
  (data) => data.card || (emptyObj as ITariffCard),
);

/** Селектор контента для карточки тарифа в блоке для Дома */
export const selectForHomeChipsContent = createSelector(
  [selectForHomeEthernetCardBlockContent],
  (data) => data.chips || (emptyObj as IChips),
);

/** Селектор контента для карточки тарифа в блоке для Дома */
export const selectForHomeCardTitle = createSelector(
  [selectForHomeEthernetCardBlockContent],
  (data) => data.title,
);

/** Селектор типа карточки в блоке для Дома */
export const selectForHomeCardIsPartnerFmc = createSelector(
  [selectForHomeEthernetCardBlockContent],
  (data) => data.type === ETHERNET_CARD_TYPES.partnerFmc,
);

/** Селектор текстов скорости тарифа в блоке для Дома */
export const selectForHomeCardPresetText = createSelector(
  [selectForHomeCardContent],
  ({ presetText }) => presetText || (emptyObj as IPresetText),
);

/** Селектор опций тарифа в блоке для Дома */
export const selectForHomeCardOptions = createSelector(
  [selectForHomeCardContent],
  ({ options }) => options || (emptyObj as IOptions),
);

/** Селектор текстов стоимости тарифа в блоке для Дома */
export const selectForHomeCardPriceText = createSelector(
  [selectForHomeCardContent],
  ({ priceText }) => priceText || (emptyObj as IPriceText),
);

// Селекторы ШПД тарифа

/** Селектор скорости тарифа в блоке для Дома */
export const selectForHomeCardSpeed = createSelector(
  [selectForHomeData],
  ({ speed }) => speed || (emptyObj as EthernetCardBlockSpeed),
);

/** Селектор стоимости тарифа в блоке для Дома */
export const selectForHomeCardFee = createSelector(
  [selectForHomeData],
  ({ fee }) => fee || (emptyObj as EthernetCardBlockFee),
);

/** Селектор для подстановки значений скорости вместо алиасов */
export const selectForHomeCardReplacedPresetText = createSelector(
  [selectForHomeCardPresetText, selectForHomeCardSpeed],
  ({ isLargeText, text, placeholderText }, { value, unit }) => {
    const replacedText =
      value && unit ? text.replace('{speed}', value).replace('{unit}', unit) : placeholderText;

    return {
      text: replacedText,
      isLargeText,
    };
  },
);

/** Селектор для подстановки значений цены и валюты вместо алиасов */
export const selectForHomeCardReplacedPriceText = createSelector(
  [selectForHomeCardPriceText, selectForHomeCardFee],
  ({ currentPrice, oldPrice }, { value, unit }) => {
    const replacedPrice =
      value && unit && currentPrice ?
        currentPrice?.replace('{price}', value as string).replace('{unit}', unit as string)
      : '';

    return {
      currentPrice: replacedPrice,
      oldPrice,
    };
  },
);

// Селекторы Партнерского конвергента
/** Селектор скорости тарифа в блоке для Дома конвергент */
export const selectForHomeConvergentCardSpeed = createSelector(
  [selectForHomeData],
  ({ speedInternet }) => speedInternet || (emptyObj as TSpeedInternet),
);

/** Селектор стоимости тарифа в блоке для Дома конвергент */
export const selectForHomeConvergentCardFee = createSelector(
  [selectForHomeData],
  ({ feeData }) => feeData || (emptyObj as TFeeData),
);

/** Селектор для подстановки значений скорости вместо алиасов */
export const selectForHomeConvergentCardSpeedText = createSelector(
  [selectForHomeCardPresetText, selectForHomeConvergentCardSpeed],
  ({ isLargeText, text, placeholderText }, { value, unit }) => {
    const replacedText =
      value && unit ?
        text.replace('{speed}', value as string).replace('{unit}', unit as string)
      : placeholderText;

    return {
      text: replacedText,
      isLargeText,
    };
  },
);

/** Селектор для подстановки значений цены и валюты вместо алиасов */
export const selectForHomeConvergentCardPriceText = createSelector(
  [selectForHomeCardPriceText, selectForHomeConvergentCardFee],
  ({ currentPrice, oldPrice }, { fee, oldFee, discountText }) => {
    const priceWithoutDiscountText =
      fee === oldFee || !discountText ? currentPrice?.split('\n')[0] : currentPrice;

    const replacedCurrentPrice =
      currentPrice && fee ?
        priceWithoutDiscountText
          .replace('{currentPrice}', String(fee))
          .replace('{discountText}', discountText)
      : '';

    const replacedOldPrice =
      oldPrice && oldFee && fee !== oldFee ? oldPrice.replace('{oldPrice}', String(oldFee)) : '';

    return {
      currentPrice: replacedCurrentPrice,
      oldPrice: replacedOldPrice,
    };
  },
);

/** Селектор иконок тарифа в блоке для Дома конвергент */
export const selectForHomeConvergentCardIcons = createSelector(
  [selectForHomeData, selectForHomeCardOptions],
  ({ icons }, options) => {
    return !isEmpty(icons) ?
        {
          ...options,
          icons,
        }
      : options;
  },
);

/** Селектор иконок тарифа в блоке для Дома конвергент */
export const selectForHomeConvergentCardSubtitle = createSelector(
  [selectForHomeData, selectForHomeCardContent],
  ({ internetPackage, minutesPackage }, { subtitle, placeholderSubtitle }) => {
    return internetPackage && minutesPackage ?
        subtitle.replace('{internet}', internetPackage).replace('{mobile}', minutesPackage)
      : placeholderSubtitle;
  },
);

// Селекторы для типа карточки NoFttb
/** Селектор типа карточки в блоке для Дома */
export const selectForHomeCardIsNoFttb = createSelector(
  [selectForHomeEthernetCardBlockContent],
  (data) => data.type === ETHERNET_CARD_TYPES.NoFttb,
);

// Универсальные селекторы для блока "Для Дома"
const selectValueByFlag = <T>(flag: boolean, convergentValue: T, fttbValue: T) =>
  flag ? convergentValue : fttbValue;

export const selectForHomeCardPreset = createSelector(
  [
    selectForHomeCardReplacedPresetText,
    selectForHomeConvergentCardSpeedText,
    selectForHomeCardIsPartnerFmc,
  ],
  (presetFttb, presetConvergent, isConvergent) =>
    selectValueByFlag(isConvergent, presetConvergent, presetFttb),
);

export const selectForHomeCardSubtitle = createSelector(
  [selectForHomeCardContent, selectForHomeConvergentCardSubtitle, selectForHomeCardIsPartnerFmc],
  ({ subtitle }, subtitleConvergent, isConvergent) =>
    selectValueByFlag(isConvergent, subtitleConvergent, subtitle || ''),
);

export const selectForHomeOptions = createSelector(
  [selectForHomeCardOptions, selectForHomeConvergentCardIcons, selectForHomeCardIsPartnerFmc],
  (optionsFttb, optionsConvergent, isConvergent) =>
    selectValueByFlag(isConvergent, optionsConvergent, optionsFttb),
);

export const selectForHomeCardPrice = createSelector(
  [
    selectForHomeCardReplacedPriceText,
    selectForHomeConvergentCardPriceText,
    selectForHomeCardIsPartnerFmc,
  ],
  (priceFttb, priceConvergent, isConvergent) =>
    selectValueByFlag(isConvergent, priceConvergent, priceFttb),
);
