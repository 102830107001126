import { useContext } from 'react';

import { TariffCardContext } from 'pages/MainPage/blocks/TariffCardBlock/components/Container';

/** Хук для использования контекста */
export const useTariffCardBlockType = () => {
  const contextData = useContext(TariffCardContext);

  return {
    section_name: contextData?.sectionTitle,
    field_name: contextData?.blockTypeString,
  };
};
