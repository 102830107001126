import { connect } from 'react-redux';

import { selectForBusinessSection } from 'pages/MainPage/store/selectors';
import { RootState } from 'pages/MainPage/types';

const mapStateToProps = (state: RootState) => {
  return {
    titleNavigation: selectForBusinessSection(state).titleNavigation,
    businessCardsBlock: selectForBusinessSection(state).businessCardsBlock,
    inlineBanner: {
      duration: selectForBusinessSection(state)?.inlineBanner?.duration,
      slides: selectForBusinessSection(state).data?.inlineBanner?.banners,
    },
    anchor: selectForBusinessSection(state).anchor,
  };
};

export const connector = connect(mapStateToProps);
