import { IconArrowsRight } from '@carnica/graphics';
import { Input as _Input, Button } from '@carnica/ui-kit';
import classNames from 'classnames/bind';
import React from 'react';
import TopupBalance from '@beep/topup';

import { WidgetLayout } from './components/WidgetLayout/WidgetLayout';
import styles from './styles.pcss';
import { TPaymentWidgetProps } from './types';

const cn = classNames.bind(styles);

export const PaymentWidget: React.FC<TPaymentWidgetProps> = ({
  textProps,
  inputProps: { Component: Input = _Input, ...inputProps },
  buttonProps,
  animationContainerProps,
  inlineButtonProps,
  text,
  topupBalanceProps,
}) => {
  return (
    <WidgetLayout
      bottomCardProps={{
        animationContainerProps,
        text,
      }}
      topCardProps={{
        textProps,
      }}
    >
      <WidgetLayout.TopCardInput>
        <Input {...inputProps} wrapperProps={{ className: cn('input') }} />
      </WidgetLayout.TopCardInput>

      <WidgetLayout.TopCardButton>
        <Button {...buttonProps} className={cn('button')} size="l" width="full" />
      </WidgetLayout.TopCardButton>

      <WidgetLayout.BottomCardButton>
        <Button
          {...inlineButtonProps}
          className={cn('inline-button')}
          iconRight={IconArrowsRight}
          iconRightView="background"
          mode="inline"
        />
        <TopupBalance {...topupBalanceProps} />
      </WidgetLayout.BottomCardButton>
    </WidgetLayout>
  );
};
