export enum OS {
  Android = 'android',
  IOS = 'ios',
}

export enum Stores {
  Android = 'googlePlay',
  Huawei = 'appGallery',
  IOS = 'appStore',
  RuStore = 'ruStore',
}

export enum YMGoals {
  PossibilitiesAtZeroClickButtonTopBanner = 'PossibilitiesAtZeroClickButtonTopBanner',
  PossibilitiesAtZeroClickButtonConnectApp = 'PossibilitiesAtZeroClickButtonConnectApp',
  PossibilitiesAtZeroClickButtonSendRequest = 'PossibilitiesAtZeroClickButtonSendRequest',
  PossibilitiesAtZeroClickBlockBottom = 'PossibilitiesAtZeroClickBlockBottom',
  PossibilitiesAtZeroClickButtonToInput = 'PossibilitiesAtZeroClickButtonToInput',
}

export interface ILandingModel<T extends Record<string, any>> {
  baseUrl: string;
  data: T;
  renderUrl: string;
}
