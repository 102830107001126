import { emptyObj } from '@beef/utils';
import { createSelector } from '@reduxjs/toolkit';

import { ETariffBlockType, RootState, SectionType } from '../types';

export const selectMainSlider = (state: RootState) =>
  state.sectionsParsed[SectionType.MainSlider].content;

export const selectForYouSection = (state: RootState) =>
  state.sectionsParsed[SectionType.ForYou].content;
export const selectServiceSectionBlock = (state: RootState) =>
  state.sectionsParsed[SectionType.ServiceSection].content.serviceBlock;
export const selectAppSectionBlock = (state: RootState) =>
  state.sectionsParsed[SectionType.AppSection].content.appBlock;

export const selectForBusinessSection = (state: RootState) =>
  state.sectionsParsed[SectionType.ForBusiness].content;

export const selectBlockSectionTitle = createSelector(
  (state: RootState) => selectForHomeSection(state)?.titleNavigation?.title,
  (state: RootState) => selectForYouSection(state)?.titleNavigation?.title,
  (state: RootState) => selectForDevicesSection(state)?.titleNavigation?.title,
  (state: RootState) => selectForFamilySection(state)?.titleNavigation?.title,
  (_, blockType) => blockType,
  (
    forHome: string,
    forYou: string,
    forDevices: string,
    forFamily: string,
    blockType: ETariffBlockType,
  ) => {
    const sectionTitles: Record<ETariffBlockType, string> = {
      [ETariffBlockType.forHome]: forHome,
      [ETariffBlockType.forYou]: forYou,
      [ETariffBlockType.forDevices]: forDevices,
      [ETariffBlockType.forFamily]: forFamily,
    };

    return sectionTitles[blockType] ? sectionTitles[blockType] : 'неизвестно';
  },
);

export const selectForDevicesSection = (state: RootState) =>
  state.sectionsParsed[SectionType.ForDevices]?.content;
export const selectForFamilySection = (state: RootState) =>
  state.sectionsParsed[SectionType.ForFamily]?.content;
export const selectForHomeSection = (state: RootState) =>
  state.sectionsParsed[SectionType.ForHome]?.content || emptyObj;
export const selectHRSection = (state: RootState) =>
  state.sectionsParsed[SectionType.HRSection].content;
export const selectHelpSection = (state: RootState) =>
  state.sectionsParsed[SectionType.HelpSection];

export const selectBreakpoint = (state: RootState) => state.breakpoint;
