import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Tag, Text } from '@carnica/ui-kit';
import { connect } from 'react-redux';

import { selectForHomeSection } from 'pages/MainPage/store/selectors';
import { RootState } from 'pages/MainPage/types';
import { IPresetText } from 'pages/MainPage/blocks/EthernetCardBlock/types';
import {
  selectForHomeCardPreset,
  selectForHomeCardSubtitle,
} from 'pages/MainPage/blocks/EthernetCardBlock/selectors';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

type THeader = {
  partnerLogo?: string;
  presetText: IPresetText;
  subtitle?: string;
  tagLabel?: string;
};

/** Компонент - хедер карточки */
const _Header: FC<THeader> = ({ tagLabel, presetText, partnerLogo, subtitle }) => (
  <div className={cx('component')}>
    {partnerLogo && <img alt="" className={cx('partner-logo')} src={partnerLogo} />}
    {tagLabel && (
      <Tag size="m" view="light">
        {tagLabel}
      </Tag>
    )}
    <Text
      color="primary-invert"
      variant={presetText.isLargeText ? 'headline-medium' : 'headline-small'}
    >
      {presetText.text}
    </Text>
    <Text color="secondary-invert" variant="body-small">
      {subtitle}
    </Text>
  </div>
);

export const Header = connect((state: RootState) => ({
  partnerLogo: selectForHomeSection(state).ethernetCardBlock?.card?.partnerLogo,
  presetText: selectForHomeCardPreset(state),
  subtitle: selectForHomeCardSubtitle(state),
  tagLabel: selectForHomeSection(state).ethernetCardBlock?.card?.tagLabel,
}))(_Header);
