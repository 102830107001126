import React from 'react';
import { compose } from '@beef/redux';
import { withErrorBoundary } from '@beef/react';

import { YourServiceBlock } from 'pages/MainPage/blocks/YourServiceBlock';
import { YourServiceBlockProps } from 'pages/MainPage/blocks/YourServiceBlock/types';

import { connector } from './connector';

const _ServiceSection: React.FC<YourServiceBlockProps> = ({ title, btnLink, btnLabel, cards }) => {
  return <YourServiceBlock btnLabel={btnLabel} btnLink={btnLink} cards={cards} title={title} />;
};

export const ServiceSection = compose(
  withErrorBoundary({ fallback: null }),
  connector,
)(_ServiceSection);
