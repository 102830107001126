import React, { FC } from 'react';
import { Text } from '@carnica/ui-kit';
import classNames from 'classnames/bind';
import { compose } from '@beef/redux';
import { connect } from 'react-redux';
import { withRenderFlag } from '@beef/react';

import { RootState } from 'pages/MainPage/types';
import { selectForHomeSection } from 'pages/MainPage/store/selectors';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент - желтая шапка */
const _CardDescription: FC<{ label?: string }> = ({ label }) => (
  <div className={cx('component')}>
    <Text variant="body-small">{label}</Text>
  </div>
);

export const CardDescription = compose<FC<{ label?: string }>>(
  connect((state: RootState) => ({
    label: selectForHomeSection(state).ethernetCardBlock?.card?.cardTitle,
    shouldRender: selectForHomeSection(state).ethernetCardBlock?.card?.cardTitle,
  })),
  withRenderFlag,
)(_CardDescription);
