import { connect } from 'react-redux';

import { selectForHomeSection } from 'pages/MainPage/store/selectors';
import { RootState } from 'pages/MainPage/types';
import { selectForHomeCardIsNoFttb } from 'pages/MainPage/blocks/EthernetCardBlock/selectors';

const mapStateToProps = (state: RootState) => {
  return {
    titleNavigation: selectForHomeSection(state).titleNavigation,
    inlineBanner: {
      duration: selectForHomeSection(state)?.inlineBanner?.duration,
      slides: selectForHomeSection(state)?.data.inlineBanner?.banners,
    },
    beelineTV: selectForHomeSection(state).beelineTV,
    devices: selectForHomeSection(state).devices,
    anchor: selectForHomeSection(state).anchor,
    noFttb: selectForHomeCardIsNoFttb(state),
  };
};

export const connector = connect(mapStateToProps);
