import React, { FC } from 'react';
import classNames from 'classnames/bind';
import { Text } from '@carnica/ui-kit';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { withRenderFlag } from '@beef/react';
import { compose } from '@beef/utils';

import { IChips } from 'pages/MainPage/blocks/EthernetCardBlock/types';
import { Chips } from 'pages/MainPage/blocks/EthernetCardBlock/components/Chips';
import { commonMetric } from 'pages/MainPage/metric/common';
import { RootState } from 'pages/MainPage/types';
import {
  selectForHomeCardTitle,
  selectForHomeChipsContent,
} from 'pages/MainPage/blocks/EthernetCardBlock/selectors';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент - блок с чипсами */
export const _ChipsBlock: FC<IChips> = ({ title, items, blockTitle }) => {
  return (
    <div className={cx('wrapper')}>
      <Text className={cx('text')} variant="body-medium">
        {title}
      </Text>
      {/* TODO: хорошим тоном читается вынос item'ов из родителя, то есть лучше во внешнем компоненте делать */}
      <div className={cx('block')}>
        {items.map((chips) => (
          <Chips
            key={chips.title}
            {...chips}
            onClick={() =>
              commonMetric.handleButtonClick(
                JSON.stringify({ blockTitle, buttonText: chips?.title }),
              )
            }
          />
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { title, items } = selectForHomeChipsContent(state);

  return {
    blockTitle: selectForHomeCardTitle(state),
    title,
    items,
    shouldRender: !isEmpty(selectForHomeChipsContent(state)),
  };
};

const connector = connect(mapStateToProps);

export const ChipsBlock = compose<FC>(connector, withRenderFlag)(_ChipsBlock);
