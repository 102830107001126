import React from 'react';
import { Text } from '@carnica/ui-kit';
import classNames from 'classnames/bind';
import { compose } from '@beef/redux';
import { connect } from 'react-redux';
import { withRenderFlag } from '@beef/react';

import { selectForHomeSection } from 'pages/MainPage/store/selectors';
import { RootState } from 'pages/MainPage/types';
import { IGift } from 'pages/MainPage/blocks/EthernetCardBlock/types';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

interface IInfo {
  gifts?: IGift[];
}

/** Блок с подарками */
const _Gifts: React.FC<IInfo> = ({ gifts }) => (
  <div className={cx('container')}>
    {gifts?.map(({ icon, title, label }) => (
      <div className={cx('info')} key={label}>
        <img alt="" src={icon} />
        <div className={cx('block')}>
          <Text color="primary-invert" size="2xs">
            {title}
          </Text>
          <Text color="secondary-invert" size="2xs">
            {label}
          </Text>
        </div>
      </div>
    ))}
  </div>
);

export const Gifts = compose<React.FC<IInfo>>(
  connect((state: RootState) => ({
    gifts: selectForHomeSection(state).ethernetCardBlock?.card?.gifts,
    shouldRender: selectForHomeSection(state).ethernetCardBlock?.card?.gifts?.length,
  })),
  withRenderFlag,
)(_Gifts);
