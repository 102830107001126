import React from 'react';
import classnames from 'classnames/bind';
import { Text } from '@carnica/ui-kit';
import { withErrorBoundary } from '@beef/react';

import { SquareBlock, SquareBlockPropsBase } from 'pages/MainPage/components/SquareBlock';
import { MnpBlock, MnpBlockProps } from 'pages/MainPage/components/MnpBlock';
import { Metric } from 'pages/MainPage/metric';

import styles from './styles.pcss';

const cx = classnames.bind(styles);

export interface NewCustomerBlockProps {
  mnpBlock: MnpBlockProps;
  squareBlocks: SquareBlockPropsBase[];
  title: string;
}

const _NewCustomerBlock: React.FC<{ blockTitle: string; data: NewCustomerBlockProps }> = ({
  data,
  blockTitle,
}) => {
  return (
    <div className={cx('wrapper')}>
      <Text className={cx('title')} variant="display-small">
        {data.title}
      </Text>
      <div className={cx('blocks')}>
        {data.squareBlocks.map((item) => (
          <Metric
            asChild
            data={JSON.stringify({
              blockTitle,
              title: item.title,
              block_name: item.blockNameMetric,
            })}
            events={{ commonMetric: ['handleSquareCardClick'] }}
            mode="click"
          >
            <SquareBlock
              backgroundImage={`url(${item.backgroundImage})`}
              backgroundPosition={item.backgroundPosition}
              backgroundPositionMobile={item.backgroundPositionMobile}
              backgroundSize={item.backgroundSize}
              backgroundSizeMobile={item.backgroundSizeMobile}
              href={item.href}
              isBlankUrlTarget={item.isBlankUrlTarget}
              key={item.title}
              subtitle={item.subtitle}
              title={item.title}
            />
          </Metric>
        ))}
      </div>
      <MnpBlock {...data.mnpBlock} blockTitle={blockTitle} />
    </div>
  );
};

export const NewCustomerBlock = withErrorBoundary({ fallback: null })(_NewCustomerBlock);
