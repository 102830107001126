import React from 'react';
import { compose } from '@beef/redux';
import { withErrorBoundary } from '@beef/react';

import { TitleWithNavigation } from 'pages/MainPage/ui';
import { VacanciesBlock, VacanciesBlockProps } from 'pages/MainPage/blocks/VacanciesBlock';
import { Section } from 'pages/MainPage/layout/Section';
import { BannerCarouselBlock } from 'pages/MainPage/blocks/BannerCarouselBlock';
import { Anchor } from 'pages/MainPage/components/Anchor';
import { BannerCarouselProps } from 'pages/MainPage/blocks/BannerCarouselBlock/types';
import { TitleWithNavigationProps } from 'pages/MainPage/ui/TitleWithNavigation/types';
import { Metric } from 'pages/MainPage/metric';
import { commonMetric } from 'pages/MainPage/metric/common';
import { BlockWrapper } from 'pages/MainPage/layout/BlockWrapper';

import { connector } from './connector';

interface IHRSectionProps {
  anchor: string;
  inlineBanner: BannerCarouselProps;
  titleNavigation: TitleWithNavigationProps;
  vacancies: VacanciesBlockProps;
}

const _HRSection: React.FC<IHRSectionProps> = ({
  titleNavigation,
  vacancies,
  inlineBanner,
  anchor,
}) => {
  return (
    <>
      <Anchor anchorId={anchor} />
      <Metric
        asChild
        data={titleNavigation.title}
        events={{ commonMetric: ['handleBlockView'] }}
        mode="viewport"
        once
        threshold={0.2}
      >
        <Section
          heading={
            <TitleWithNavigation
              items={titleNavigation.items}
              onNavigationItemClick={(item) =>
                commonMetric.handleBlockNavClick(item.text, titleNavigation.title)
              }
              title={titleNavigation.title}
            />
          }
        >
          {inlineBanner?.slides && (
            <BlockWrapper>
              <BannerCarouselBlock {...inlineBanner} sectionTitle={titleNavigation.title} />
            </BlockWrapper>
          )}
          <VacanciesBlock {...vacancies} />
        </Section>
      </Metric>
    </>
  );
};

export const HRSection = compose(withErrorBoundary({ fallback: null }), connector)(_HRSection);
