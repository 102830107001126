import React from 'react';
import classNames from 'classnames/bind';
import { withErrorBoundary } from '@beef/react';

import { MenuUrlTarget } from 'pages/MainPage/utils/utils';

import { ContentBlock, ContentBlockProps } from './components/ContentBlock';
import { AppBlock, IAppBlock } from './components/AppBlock';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

export interface BeelineTVProps {
  appBlock: IAppBlock;
  contentBlock: ContentBlockProps;
}

const _BeelineTV: React.FC<BeelineTVProps> = ({ contentBlock, appBlock }) => {
  return (
    <div className={cx('wrapper')}>
      <a href={contentBlock.buttonLink} target={MenuUrlTarget.get(contentBlock.isBlankUrlTarget)}>
        <ContentBlock {...contentBlock} />
      </a>
      <AppBlock {...appBlock} />
    </div>
  );
};

export const BeelineTV = withErrorBoundary({ fallback: null })(_BeelineTV);
