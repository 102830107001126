import React, { RefObject, useMemo } from 'react';
import { TabBar } from '@carnica/ui-kit';
import { compose, getStore } from '@beef/redux';
import { withDataConverter, withErrorBoundary } from '@beef/react';
import type { MenuDefaultItem, TabBarDefaultItem } from '@carnica/ui-kit/types';
import { Provider } from 'react-redux';

import { ISectionParsed } from 'pages/MainPage/types';
import { commonMetric } from 'pages/MainPage/metric/common';

import { convertTabBarBlock, handleItemClick } from './utils';
import { useNearestSection } from './hooks/useNearestPosition';
import { TabBarWrapper } from './components/TabBarWrapper';
import { connector } from './connector';

export interface TabBarBlockProps {
  anchorsIds: string[];
  isMobile: boolean;
  items: TabBarDefaultItem[];
  menu: MenuDefaultItem[];
  sectionRef: RefObject<HTMLDivElement>;
  sectionsParsed: ISectionParsed;
}

export const _TabBarBlock: React.FC<TabBarBlockProps> = ({
  sectionRef,
  menu,
  items,
  anchorsIds,
  isMobile,
  sectionsParsed,
}) => {
  const activeSection = useNearestSection(anchorsIds, sectionsParsed);
  const activeMenuItem = useMemo(
    () => items.find((item) => item.key === activeSection),
    [activeSection],
  );

  const store = getStore('headerStore');
  return (
    <Provider store={store}>
      <TabBarWrapper isMobile={isMobile} sectionRef={sectionRef}>
        <TabBar
          closeMenuOnScroll
          getMenuAttributes={(item: MenuDefaultItem) => ({
            href: item.href,
            target: item.target,
          })}
          getMenuTagName={(item: MenuDefaultItem) => (item.href ? 'a' : 'button')}
          items={items}
          menu={menu}
          menuAttributes={{
            style: { zIndex: 25 },
          }}
          onItemClick={(item) => {
            commonMetric.handleTabBarClick(item.label);
            handleItemClick(item.key);
          }}
          onMenuButtonClick={() => commonMetric.handleTabBarButtonClick()}
          onMenuClick={(item) => commonMetric.handleTabBarMenuClick(item.label)}
          position={isMobile ? 'fixed' : 'default'}
          value={activeMenuItem}
        />
      </TabBarWrapper>
    </Provider>
  );
};

export const TabBarBlock = compose(
  withErrorBoundary({ fallback: null }),
  connector,
  withDataConverter(convertTabBarBlock),
)(_TabBarBlock);
