import { useMemo } from 'react';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';
import { ICardTitle } from 'pages/MainPage/blocks/TariffCardBlock/types';
import { useTariffCardBlockType } from 'pages/MainPage/blocks/TariffCardBlock/components/Container/hooks/useTariffCardBlockType';

interface IUsePrepareCard {
  bgImage?: string;
  cardTitle?: ICardTitle;
  tagLabel?: string;
}

interface IMetricData {
  field_name: string | undefined;
  section_name: string | undefined;
  tariffLabel?: string;
}

/** Хук подготовки карточки */
export const usePrepareTariffCard = ({ cardTitle, bgImage, tagLabel }: IUsePrepareCard) => {
  const isMobile = useIsMobileDimension();
  const { mobile, tablet } = cardTitle || {};

  /* Вариант текста для мобилки, если он есть */
  const label = (isMobile && mobile) || tablet;

  /* Для метрики */
  const baseMetricData = useTariffCardBlockType() as IMetricData;

  return useMemo(
    () => ({
      label,
      style: bgImage ? { backgroundImage: `url(${bgImage})` } : {},
      metricData: JSON.stringify({
        ...baseMetricData,
        tariff_label: tagLabel,
      }),
    }),
    [label, bgImage, tagLabel, baseMetricData],
  );
};
