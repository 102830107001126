import { useMemo } from 'react';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';
import { useTariffCardBlockType } from 'pages/MainPage/blocks/TariffCardBlock/components/Container/hooks/useTariffCardBlockType';

/** Хук для подготовки чипсин */
export const usePrepareChips = (title: string) => {
  const isMobile = useIsMobileDimension();
  const metricFields = useTariffCardBlockType();

  return useMemo(
    () => ({
      metricData: JSON.stringify({ title, ...metricFields }),
      isMobile,
    }),
    [title, metricFields, isMobile],
  );
};
