import { useCallback, useEffect, useState } from 'react';
import { clientOnlyHook } from '@beef/utils';

import {
  DURATION_LARGE,
  DURATION_MEDIUM,
  DURATION_SMALL,
  HEIGHT_THRESHOLD_LARGE,
  HEIGHT_THRESHOLD_MEDIUM,
} from 'pages/MainPage/blocks/TabBarBlock/constants';

export const _useTransitionDuration = (): string => {
  const calculateDuration = useCallback(() => {
    if (window.innerHeight >= HEIGHT_THRESHOLD_LARGE) return DURATION_LARGE;
    if (window.innerHeight >= HEIGHT_THRESHOLD_MEDIUM) return DURATION_MEDIUM;
    return DURATION_SMALL;
  }, []);

  const [transitionDuration, setTransitionDuration] = useState(calculateDuration);

  useEffect(() => {
    const handleResize = () => setTransitionDuration(calculateDuration());
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return transitionDuration;
};

export const useTransitionDuration = clientOnlyHook(_useTransitionDuration);
