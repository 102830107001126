import React, { CSSProperties, FC } from 'react';
import classNames from 'classnames/bind';
import { compose } from '@beef/redux';
import { connect } from 'react-redux';
import { withRenderFlag } from '@beef/react';

import { Header } from 'pages/MainPage/blocks/EthernetCardBlock/components/Header';
import { Footer } from 'pages/MainPage/blocks/EthernetCardBlock/components/Footer';
import { CardDescription } from 'pages/MainPage/blocks/EthernetCardBlock/components/CardDescription';
import { Options } from 'pages/MainPage/blocks/EthernetCardBlock/components/Options';
import { Gifts } from 'pages/MainPage/blocks/EthernetCardBlock/components/Gifts';
import { ConnectButton } from 'pages/MainPage/blocks/EthernetCardBlock/components/ConnectButton';
import { ITariffCard } from 'pages/MainPage/blocks/EthernetCardBlock/types';
import { Metric } from 'pages/MainPage/metric';
import { RootState } from 'pages/MainPage/types';
import { selectForHomeSection } from 'pages/MainPage/store/selectors';

import styles from './styles.pcss';

const cx = classNames.bind(styles);

/** Компонент карточки тарифа */
export const _TariffCard: FC<ITariffCard> = ({ bgImage, cardTitle, tagLabel, button }) => {
  const style: CSSProperties = {
    ...(bgImage ? { backgroundImage: `url(${bgImage})` } : {}),
  };

  return (
    <div className={cx('wrapper')}>
      <CardDescription />
      <Metric
        asChild
        data={tagLabel}
        events={{ commonMetric: ['handleEthernetBlockClick'] }}
        mode="click"
      >
        <div className={cx('content', !cardTitle && 'content--no-offset')} style={style}>
          <Header />
          <Options />
          <Gifts />
          <Footer />
        </div>
      </Metric>
      <Metric
        asChild
        data={button?.text}
        events={{ commonMetric: ['handleEthernetButtonClick'] }}
        mode="click"
      >
        <ConnectButton />
      </Metric>
    </div>
  );
};

export const TariffCard = compose<FC>(
  connect((state: RootState) => ({
    bgImage: selectForHomeSection(state).ethernetCardBlock?.card?.bgImage,
    cardTitle: selectForHomeSection(state).ethernetCardBlock?.card?.cardTitle,
    tagLabel: selectForHomeSection(state).ethernetCardBlock?.card?.tagLabel,
    button: selectForHomeSection(state).ethernetCardBlock?.card?.button,
    shouldRender: selectForHomeSection(state).ethernetCardBlock?.card,
    ...selectForHomeSection(state).ethernetCardBlock?.card,
  })),
  withRenderFlag,
)(_TariffCard);
