import React from 'react';
import classNames from 'classnames/bind';
import { compose } from '@beef/redux';
import { withErrorBoundary } from '@beef/react';

import { MainCarouselBlock } from 'pages/MainPage/blocks/MainCarouselBlock';
import { MainCarouselProps } from 'pages/MainPage/blocks/MainCarouselBlock/types';

import { connector } from './connector';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

interface MainCarouselOwnProps {
  innerRef?: React.Ref<HTMLDivElement>;
}

type MainCarouselSectionProps = MainCarouselProps & MainCarouselOwnProps;

const _MainCarouselSection: React.FC<MainCarouselSectionProps> = ({
  slides,
  innerRef,
  duration,
}) => {
  return (
    <div className={cx('sections')} ref={innerRef}>
      <MainCarouselBlock duration={duration} slides={slides} />
    </div>
  );
};

export const MainCarouselSection: React.FC<MainCarouselOwnProps> = compose(
  withErrorBoundary({ fallback: null }),
  connector,
)(_MainCarouselSection);
