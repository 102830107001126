import { YMMetricHelper } from '@beef/metric';

/* Ссылка на тз: https://bwiki.beeline.ru/pages/viewpage.action?pageId=635841267 */
export class TariffCardMetric extends YMMetricHelper {
  protected readonly TARIFF_CARD_PARAMS_KEY = 'main';

  protected setTariffCardInfo = (params: Record<string, any>) => {
    if (typeof window === 'undefined')
      return {
        [this.TARIFF_CARD_PARAMS_KEY]: {
          ...params,
        },
      };
    return {
      [this.TARIFF_CARD_PARAMS_KEY]: {
        page_title: document.title,
        page_url: window.location.href,
        page_path: window.location.pathname,
        ...params,
      },
    };
  };

  /* п.4 Клик на карточку */
  handleCardClick(data: string) {
    const parsedData = JSON.parse(data);
    this.sendParamsEvent(
      this.setTariffCardInfo({
        action: 'click',
        item_type: 'card',
        card_name: 'карточка тарифа',
        object_type: 'block',
        block_name: 'выбери тариф',
        section_name: parsedData.section_name,
        card_title: parsedData.tariff_label,
      }),
    );
  }

  /* п.4.1 Клик по кнопке Подключить */
  handleConnectClick(data: string) {
    const { text, section_name, tariff_label } = JSON.parse(data);
    this.sendParamsEvent(
      this.setTariffCardInfo({
        action: 'click',
        item_type: 'button',
        button_text: text,
        card_name: 'карточка тарифа',
        object_type: 'block',
        section_name,
        card_title: tariff_label,
      }),
    );
  }

  /* п.4.2 Клик по чипсам тарифов */
  handleChipsClick(data: string) {
    const { title, section_name } = JSON.parse(data);
    this.sendParamsEvent(
      this.setTariffCardInfo({
        action: 'click',
        item_type: 'tab',
        tab_text: title,
        block_name: 'выбери тариф',
        section_name,
        object_type: 'block',
      }),
    );
  }
}

export const tariffCardMetric = new TariffCardMetric({
  counterId: 26001372,
  isEcommerce: false,
  debug: true,
});
