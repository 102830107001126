import React from 'react';
import { Container } from '@carnica/smart-kit';
import classNames from 'classnames/bind';
import { compose } from '@beef/redux';
import { withErrorBoundary } from '@beef/react';

import { BeelineTV, BeelineTVProps } from 'pages/MainPage/blocks/BeelineTV';
import { Section } from 'pages/MainPage/layout/Section';
import { Devices } from 'pages/MainPage/components/Devices';
import { TitleWithNavigation } from 'pages/MainPage/ui';
import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';
import { BannerCarouselBlock } from 'pages/MainPage/blocks/BannerCarouselBlock';
import { Anchor } from 'pages/MainPage/components/Anchor';
import { AdaptivityContainer } from 'pages/MainPage/utils/ResponsiveContainers';
import { BlockWrapper } from 'pages/MainPage/layout/BlockWrapper';
import { IDevice } from 'pages/MainPage/components/Devices/types';
import { BannerCarouselProps } from 'pages/MainPage/blocks/BannerCarouselBlock/types';
import { TitleWithNavigationProps } from 'pages/MainPage/ui/TitleWithNavigation/types';
import { EthernetCardBlock } from 'pages/MainPage/blocks/EthernetCardBlock';
import { Metric } from 'pages/MainPage/metric';
import { commonMetric } from 'pages/MainPage/metric/common';
import { TariffCardBlock } from 'pages/MainPage/blocks/TariffCardBlock';
import { ETariffBlockType } from 'pages/MainPage/types';

import styles from './styles.pcss';
import { connector } from './connector';

const cx = classNames.bind(styles);

interface IForHomeProps {
  anchor: string;
  beelineTV: BeelineTVProps;
  devices: IDevice;
  inlineBanner: BannerCarouselProps;
  noFttb: boolean;
  titleNavigation: TitleWithNavigationProps;
}

const _ForHome: React.FC<IForHomeProps> = ({
  titleNavigation,
  inlineBanner,
  beelineTV,
  devices,
  anchor,
  noFttb,
}) => {
  const isMobile = useIsMobileDimension();
  return (
    <>
      <Anchor anchorId={anchor} />
      <Metric
        asChild
        data={titleNavigation.title}
        events={{ commonMetric: ['handleBlockView'] }}
        mode="viewport"
        once
        threshold={0.2}
      >
        <Section
          heading={
            <TitleWithNavigation
              items={titleNavigation.items}
              onNavigationItemClick={(item) =>
                commonMetric.handleBlockNavClick(item.text, titleNavigation.title)
              }
              title={titleNavigation.title}
            />
          }
          style={{ marginBottom: isMobile && 0 }}
        >
          <BlockWrapper>
            <Container.Flex gap={60}>
              <AdaptivityContainer minWidth={1025}>
                <Container.ColumnGrid className={cx('grid-block')} template={['1fr', '1fr']}>
                  <BeelineTV {...beelineTV} />
                  {noFttb ?
                    <TariffCardBlock blockType={ETariffBlockType.forHome} />
                  : <EthernetCardBlock />}
                </Container.ColumnGrid>
              </AdaptivityContainer>
              <AdaptivityContainer maxWidth={1024} minWidth={481}>
                <Container.FlexFactor grow={1}>
                  <Container.Flex direction="column" gap={20}>
                    {noFttb ?
                      <TariffCardBlock blockType={ETariffBlockType.forHome} />
                    : <EthernetCardBlock />}
                    <BeelineTV {...beelineTV} />
                  </Container.Flex>
                </Container.FlexFactor>
              </AdaptivityContainer>
              <AdaptivityContainer maxWidth={480}>
                <Container.Flex className={cx('container')} direction="column" gap={4}>
                  {noFttb ?
                    <TariffCardBlock blockType={ETariffBlockType.forHome} />
                  : <EthernetCardBlock />}
                  <BeelineTV {...beelineTV} />
                </Container.Flex>
              </AdaptivityContainer>
            </Container.Flex>
          </BlockWrapper>

          {inlineBanner?.slides && (
            <BlockWrapper>
              <BannerCarouselBlock {...inlineBanner} sectionTitle={titleNavigation.title} />
            </BlockWrapper>
          )}

          <BlockWrapper>
            <Devices {...devices} blockTitle={titleNavigation.title} />
          </BlockWrapper>
        </Section>
      </Metric>
    </>
  );
};

export const ForHome = compose(withErrorBoundary({ fallback: null }), connector)(_ForHome);
