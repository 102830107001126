import React, { FC, ReactNode, createContext, useMemo } from 'react';
import classNames from 'classnames/bind';

import styles from './styles.pcss';
import { connector } from './connector';

const cx = classNames.bind(styles);

type TContainer = {
  blockTypeString: string;
  children: ReactNode;
  sectionTitle: string;
};

interface IContext {
  blockTypeString: string;
  sectionTitle: string;
}

export const TariffCardContext = createContext<IContext | undefined>(undefined);

/** Компонент - обертка карточки */
const _ContainerProvider: FC<TContainer> = ({ blockTypeString, children, sectionTitle }) => {
  const contextValue = useMemo(
    () => ({ blockTypeString, sectionTitle }),
    [blockTypeString, sectionTitle],
  );

  return (
    <TariffCardContext.Provider value={contextValue}>
      <div className={cx('wrapper')}>{children}</div>
    </TariffCardContext.Provider>
  );
};

export const ContainerProvider = connector(_ContainerProvider);
