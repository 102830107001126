import React from 'react';
import classNames from 'classnames/bind';
import { Container } from '@carnica/smart-kit';
import { withErrorBoundary, withRenderFlag } from '@beef/react';

import { useIsMobileDimension } from 'utils/hooks/useGetDimensionGroup';
import {
  AppPromoteBlock as _AppPromoteBlock,
  AppPromoteBlockProps,
} from 'pages/MainPage/components/MnpBlock/component/AppPromoteBlock';
import { MenuUrlTarget } from 'pages/MainPage/utils/utils';
import { Metric } from 'pages/MainPage/metric';

import styles from './styles.pcss';
import { MnpContent } from './component/MnpContent';

const cx = classNames.bind(styles);

export interface MnpBlockProps {
  appPromoteBlock: AppPromoteBlockProps;
  blockTitle: string;
  buttonText: string;
  desktopIcon: string;
  href: string;
  isBlankUrlTarget: boolean;
  mobileIcon: string;
  subtitle: string;
  title: string;
}

const AppPromoteBlock: React.FC<AppPromoteBlockProps & { shouldRender: boolean }> =
  withRenderFlag(_AppPromoteBlock);

const _MnpBlock: React.FC<MnpBlockProps> = ({
  title,
  subtitle,
  buttonText,
  mobileIcon,
  desktopIcon,
  href,
  isBlankUrlTarget,
  appPromoteBlock,
  blockTitle,
}) => {
  const isMobile = useIsMobileDimension();

  const linkProps = {
    href,
    target: MenuUrlTarget.get(isBlankUrlTarget),
    className: cx('link'),
  };

  return (
    <Container.Flex
      className={cx('wrapper')}
      direction={isMobile ? 'column' : 'row'}
      justifyContent="space-between"
    >
      <Metric asChild data={blockTitle} events={{ commonMetric: ['handleMnpClick'] }} mode="click">
        <a {...linkProps} className={cx('content-min-tablet')}>
          <Container.Flex justifyContent="space-between">
            <MnpContent
              blockTitle={blockTitle}
              buttonText={buttonText}
              desktopIcon={desktopIcon}
              href={href}
              isBlankUrlTarget={isBlankUrlTarget}
              isMobile={isMobile}
              mobileIcon={mobileIcon}
              subtitle={subtitle}
              title={title}
            />
            <AppPromoteBlock
              {...appPromoteBlock}
              blockTitle={blockTitle}
              shouldRender={!isMobile}
            />
          </Container.Flex>
        </a>
      </Metric>
      <AppPromoteBlock {...appPromoteBlock} blockTitle={blockTitle} shouldRender={isMobile} />
    </Container.Flex>
  );
};

export const MnpBlock = withErrorBoundary({ fallback: null })(_MnpBlock);
