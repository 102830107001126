import React from 'react';
import { withErrorBoundary } from '@beef/react';

import { TariffCard } from 'pages/MainPage/blocks/EthernetCardBlock/components/TariffCard';

import { Section } from './components/Section';
import { ChipsBlock } from './components/ChipsBlock';
import { Title } from './components/Title';

export const _EthernetCardBlock = () => (
  <Section>
    <Title />
    <TariffCard />
    <ChipsBlock />
  </Section>
);

export const EthernetCardBlock = withErrorBoundary()(_EthernetCardBlock);
