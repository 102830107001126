import React, { ReactNode, RefObject, useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import { useCalculateTransformStyle } from '../../hooks/useCalculateTransformStyle';
import { useTransitionDuration } from '../../hooks/useTransitionDuration';
import styles from './styles.pcss';

const cx = classNames.bind(styles);

interface TabBarWrapperProps {
  children: ReactNode;
  isBannerHidden: boolean;
  isMobile: boolean;
  sectionRef: RefObject<HTMLDivElement>;
}

export const TabBarWrapper: React.FC<TabBarWrapperProps> = ({
  sectionRef,
  children,
  isMobile,
  isBannerHidden,
}) => {
  const transformStyle = useCalculateTransformStyle(sectionRef, isBannerHidden);
  const transitionDuration = useTransitionDuration();
  const [deferredRenderer, setDeferredRenderer] = useState(false);

  useEffect(() => {
    const renderTimer = setTimeout(() => {
      setDeferredRenderer(true);
    }, 100);

    return () => clearTimeout(renderTimer);
  }, []);

  return (
    <div
      className={cx('wrapper')}
      style={{
        transform: isMobile ? 'unset' : transformStyle,
        left: isMobile ? 'unset' : '50%',
        bottom: isMobile ? 'unset' : 40,
        position: isMobile ? 'relative' : 'fixed',
        opacity: deferredRenderer ? '1' : '0',
        transition:
          deferredRenderer && !isMobile ?
            `transform ${transitionDuration} linear, opacity 0.2s ease-in-out`
          : 'unset',
      }}
    >
      {children}
    </div>
  );
};
